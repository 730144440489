$color-valid: green;
$color-invalid: red;
$color-green: #249690;

@mixin react-input-style-default {
    width: 100%;
    height: 61px;
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 12px 24px;
    box-sizing: border-box;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #414141;

    &::placeholder {
        color: #dbdbdb;
    }

    background: linear-gradient(0deg, #f2f2f2, #f2f2f2), #ffffff;
}

@mixin react-input-style-focused {
    color: #5a5a5a;
    background: #ffffff;
    border: 1px solid $color-green;
    caret-color: #4c8582;
    padding: 11px 23px;
}

@mixin react-input-style-filled {
    border: 1px solid #dbdbdb;
    padding: 11px 23px;
}

._react-input-text,
._react-input-email,
._react-input-password,
._react-input-select,
._react-input-textarea {
    @include react-input-style-default;

    &._react-input-focused {
        @include react-input-style-focused;
    }

    &:not(._react-input-focused):not(:placeholder-shown) {
        @include react-input-style-filled;
    }
}

._react-input-checkbox {
    > label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
    }

    > input {
        accent-color: #249690;
    }
}

._react-input-phone > input {
    @include react-input-style-default;
    padding-left: 48px;
}

._react-input-phone._react-input-focused {
    input {
        @include react-input-style-focused;
        padding-left: 47px;
    }

    .flag-dropdown {
        border-left-color: $color-green;
        border-top-color: $color-green;
        border-bottom-color: $color-green;
    }
}

._react-input-phone:not(._react-input-focused) > input:not(:placeholder-shown) {
    @include react-input-style-filled;
    padding-left: 47px;
}

@mixin input-el-noflag {
    &._react-input-phone input {
        @content;
    }

    &._react-input-checkbox input {
        @content;
    }

    &:not(._react-input-phone):not(._react-input-checkbox) {
        @content;
    }
}

@mixin input-el {
    @include input-el-noflag {
        @content;
    }

    &._react-input-phone .flag-dropdown {
        @content;
    }
}

._react-input-valid {
    @include input-el {
        border-color: $color-valid;
    }
}

._react-input-invalid {
    @include input-el {
        border-color: $color-invalid;
    }
}

/*
._react-input-focused {
    @include input-el-noflag {
        box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }

    &._react-input-valid {
        @include input-el-noflag {
            box-shadow: 0 0 0 0.2rem rgb(0 255 0 / 25%);
        }
    }

    &._react-input-invalid {
        @include input-el-noflag {
            box-shadow: 0 0 0 0.2rem rgb(255 0 0 / 25%);
        }
    }
}
*/

._react-input-focused:not(._react-input-validation-forceShow)
    + ._react-input-feedback {
    display: none;
}

._react-input-feedback {
    &._react-input-feedback-valid {
        color: $color-valid;
    }

    &._react-input-feedback-invalid {
        color: $color-invalid;
    }
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}

a.no-decorate {
    &:link {
        text-decoration: none;
    }

    &:visited {
        text-decoration: none;
    }

    &:hover {
        text-decoration: none;
    }

    &:active {
        text-decoration: none;
    }
}

a:has(button) {
    text-decoration: none;
}

* {
    font-family: 'Poppins';
}
